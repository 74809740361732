import { createStore, createSubscriber, createHook } from "react-sweet-state";
import { sortBy } from "lodash";
import { CardModel, GameModel } from ".";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    cards: [] as CardModel[],
    game: {} as GameModel,
  },
  // actions that trigger store mutation
  actions: {
    updateCard: (card: CardModel) => ({ setState, getState }) => {
      // mutate state synchronously
      setState({
        cards: sortBy(
          getState().cards.map((c) => (c.id === card.id ? card : c)),
          "order"
        ),
      });
    },
    updateGame: (game: GameModel) => ({ setState, getState }) => {
      setState({
        game,
      });
    },
    updateCards: (cards: CardModel[]) => ({ setState, getState }) => {
      setState({
        cards,
      });
    },
  },
});

export const useGameState = createHook(Store);
