import React, { useState, useCallback } from "react";
import { StyleSheet, View, Text, ScrollView } from "react-native";
import { Button } from "react-native-elements";
import { Game, GameLayout } from "models";
import { routes } from "lib/routing/use-routing";
import { API, graphqlOperation } from "aws-amplify";
import { createGame } from "graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { CreateGameMutation } from "API";
import background from "assets/animations/sunrise-background.json";
import { useI18n } from "hooks/use-i18n";
import { I18n } from "aws-amplify";
import MemoMe from "assets/memome-logo.svg";
import LottieViewer from "components/Lottie/LottieView";
import Constants from "expo-constants";
import { Analytics as GA, Event, PageHit } from "expo-analytics";
import * as WebBrowser from "expo-web-browser";

export default function Home() {
  const [i18nKeys, i18nGet] = useI18n();
  const analytics = new GA(Constants.manifest.extra.analytics);
  const generateRandomString = (length = 6) =>
    Math.random().toString(20).substr(2, 6);
  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      analytics.hit(new PageHit("Home"));
    }, [])
  );

  const newGame = () =>
    API.graphql(
      graphqlOperation(createGame, {
        input: {
          slug: generateRandomString(),
          layout: GameLayout.G4X4,
          isDone: false,
        },
      })
    ) as Promise<GraphQLResult<CreateGameMutation>>;

  const goToGame = (id: string) => navigation.navigate(routes.game, { id });

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.title}>
        <MemoMe width="50%" />
      </View>
      <Button
        title={i18nGet(i18nKeys.Play)}
        titleStyle={styles.button}
        onPress={() => {
          newGame().then((game) => goToGame(game.data?.createGame?.id!));
        }}
      />
      <View style={styles.textContainer}>
        <Text style={styles.statement}>{i18nGet(i18nKeys.ZoeQuote)}</Text>
        <Text style={styles.statementSig}>Zoe M.</Text>
      </View>
      <View style={styles.closeContainer}>
        <Text style={styles.closeStatement}>Made with ❤️ by Zoe</Text>
      </View>
      <Button
        type="clear"
        title={i18nGet(i18nKeys.Feedback)}
        onPress={() =>
          WebBrowser.openBrowserAsync(
            `mailto:Memo Me <memomefun@gmail.com>?subject=${i18nGet(
              i18nKeys.Feedback
            )}`
          )
        }
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
    width: "100%",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: 50,
  },
  statement: {
    fontFamily: "CraftyGirls-Regular",
    fontSize: 20,
  },
  textContainer: {
    marginTop: 50,
    width: "50%",
    ...elevationShadowStyle(5),
    padding: 30,
  },
  statementSig: {
    alignSelf: "flex-end",
  },
  closeContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  closeStatement: {
    fontSize: 10,
  },
  button: {
    padding: 50,
    fontSize: 30,
  },
});

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0.5 * elevation },
    shadowOpacity: 0.3,
    shadowRadius: 0.8 * elevation,
  };
}
