const dictionary = {
  en: {
    Play: "Play",
    ZoeQuote: "Finally, I can play with my grandparents again.",
    GetStarted: "Let's get this party started.",
    EnterName: "Enter your name.",
    WaitingForPlayer: "Waiting for the other player to join",
    NoSharing: "Use the share button or copy the URL to invite a friend.",
    Sharing: "Invite a friend",
    And: "and",
    WinnerPostfix: "won this round! Congrats",
    NextRound: "Next round starting shortly",
    InvitedToPlay: "invited you to play",
    NameInUse: "Another player already uses this name",
    Done: "Done",
    InviteTitle: "I challenge you to Memo Me",
    YourTurn: "It's your turn, click on a card",
    Feedback: "What do you think about my game?",
  },
  de: {
    Play: "Los geht's",
    ZoeQuote: "Endlich kann ich wieder mit Oma und Opa spielen.",
    GetStarted: "Lass uns loslegen.",
    EnterName: "Wie heisst du?",
    WaitingForPlayer: "Wir müssen noch kurz auf den anderen Spieler warten.",
    NoSharing:
      "Benutze den 'teilen' Knopf im Browser oder kopiere den Link und versende ihn.",
    Sharing: "Lade einen Freund ein.",
    And: "und",
    WinnerPostfix: "! Glückwunsch",
    NextRound: "Eine neue Runde beginnt gleich",
    InvitedToPlay: "hat dich auf ein Spiel eingeladen",
    NameInUse: "Der andere Spieler benutzt bereits diesen Namen",
    Done: "Weiter",
    InviteTitle: "Ich fordere Dich zu Memo Me heraus",
    YourTurn: "Du bist am Zug. Klicke of eine Karte",
    Feedback: "Was hälst du von meinem Spiel?",
  },
};

export const KeyEntries = {
  Play: "Play",
  ZoeQuote: "ZoeQuote",
  GetStarted: "GetStarted",
  EnterName: "EnterName",
  WaitingForPlayer: "WaitingForPlayer",
  NoSharing: "NoSharing",
  Sharing: "Sharing",
  And: "And",
  WinnerPostfix: "WinnerPostfix",
  NextRound: "NextRound",
  InvitedToPlay: "InvitedToPlay",
  NameInUse: "NameInUse",
  Done: "Done",
  InviteTitle: "InviteTitle",
  YourTurn: "YourTurn",
  Feedback: "Feedback",
};

export default dictionary;
