import { useRoute } from "@react-navigation/native";
import { get } from "lodash";

export default function useNavigationParam<T = any>(
  key: string,
  defaultValue: any = null
): T {
  const params = useRoute().params;
  return get(params, key, defaultValue);
}
