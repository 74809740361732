import { useMemo } from "react";
import { I18n } from "aws-amplify";
import dictionary, { KeyEntries } from "../../i18n";
import { useEffect } from "react";
import { get, keys, fromPairs, Dictionary } from "lodash";

type KeyType = typeof KeyEntries;
export function useI18n(): [KeyType, (a: string) => string] {
  I18n.putVocabularies(dictionary);

  const i18nGet = (key: string) => I18n.get(key, get(dictionary.en, key));
  return [KeyEntries, i18nGet];
}
