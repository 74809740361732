/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGameUpdate = /* GraphQL */ `
  subscription OnGameUpdate($id: ID) {
    onGameUpdate(id: $id) {
      id
      slug
      players
      scores
      currentPlayer
      layout
      identifiedPairs
      isDone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCardUpdate = /* GraphQL */ `
  subscription OnCardUpdate($gameCardId: String) {
    onCardUpdate(gameCardId: $gameCardId) {
      id
      order
      open
      name
      gameCardId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGame = /* GraphQL */ `
  subscription OnCreateGame {
    onCreateGame {
      id
      slug
      players
      scores
      currentPlayer
      layout
      identifiedPairs
      isDone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGame = /* GraphQL */ `
  subscription OnUpdateGame {
    onUpdateGame {
      id
      slug
      players
      scores
      currentPlayer
      layout
      identifiedPairs
      isDone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGame = /* GraphQL */ `
  subscription OnDeleteGame {
    onDeleteGame {
      id
      slug
      players
      scores
      currentPlayer
      layout
      identifiedPairs
      isDone
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCard = /* GraphQL */ `
  subscription OnCreateCard {
    onCreateCard {
      id
      order
      open
      name
      gameCardId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCard = /* GraphQL */ `
  subscription OnUpdateCard {
    onUpdateCard {
      id
      order
      open
      name
      gameCardId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCard = /* GraphQL */ `
  subscription OnDeleteCard {
    onDeleteCard {
      id
      order
      open
      name
      gameCardId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
