import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import * as Animatable from "react-native-animatable";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { routes } from "lib/routing/use-routing";
import Constants from "expo-constants";
import { Analytics as GA, Event } from "expo-analytics";
import { useI18n } from "hooks/use-i18n";

interface IHeaderProps {
  id: string;
  players: IPlayer[];
  showAlert: boolean;
}

export interface IPlayer {
  name: string;
  score: number;
  hasTurn: boolean;
}

export default function Header({ players, id, showAlert }: IHeaderProps) {
  useEffect(() => {}, []);
  const [i18nKeys, i18nGet] = useI18n();
  const analytics = new GA(Constants.manifest.extra.analytics);
  const navigation = useNavigation();
  return (
    <>
      {!showAlert && (
        <View style={styles.container}>
          {players.map((p, i) => {
            const turnStyle = p.hasTurn ? styles.turn : {};
            return (
              <Animatable.Text
                transition={["fontSize", "color"]}
                key={i}
                style={[styles.title, turnStyle]}
              >{`${p.name}: ${p.score}`}</Animatable.Text>
            );
          })}
          <View style={styles.exit}>
            <Ionicons
              name="md-exit"
              size={32}
              color="#aaaaaa"
              onPress={() => {
                const leave = confirm("Do you really want to leave the game?");
                if (leave) {
                  analytics.event(new Event("Game", "Exit", id));
                  navigation.navigate(routes.home);
                }
              }}
            />
          </View>
        </View>
      )}
      {showAlert && (
        <View style={alertStyles.container}>
          <Animatable.Text
            animation="pulse"
            easing="ease-out"
            iterationCount="infinite"
            style={alertStyles.title}
          >
            {i18nGet(i18nKeys.YourTurn)}
          </Animatable.Text>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    height: 80,
  },
  title: {
    flex: 1,
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 10,
    color: "#aaaaaa",
  },
  turn: {
    color: "#A47796",
    fontSize: 20,
  },
  exit: {
    width: 35,
    alignItems: "flex-end",
    alignSelf: "flex-end",
  },
});

const alertStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    height: 80,
    backgroundColor: "#A47796",
  },
  title: {
    flex: 1,
    fontSize: 20,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
  },
});
