// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const GameLayout = {
  "G3X3": "g3x3",
  "G3X4": "g3x4",
  "G4X4": "g4x4",
  "G4X5": "g4x5"
};

const { Game, Card } = initSchema(schema);

export {
  Game,
  Card,
  GameLayout
};