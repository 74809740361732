import React, { RefObject, useEffect } from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import Amplify from "aws-amplify";
import Analytics from "@aws-amplify/analytics";
import { routes } from "./src/lib/routing/use-routing";
import { useFonts } from "@use-expo/font";
import { AppLoading } from "expo";
import useUpdateCheck from "hooks/use-update-check";
import Constants from "expo-constants";
import { Analytics as GA, Event } from "expo-analytics";
import { ExceptionHit } from "lib/analytics/Exception";
import {
  NavigationContainer,
  NavigationContainerRef,
  NavigationProp,
  DefaultTheme,
} from "@react-navigation/native";
import {
  createStackNavigator,
  HeaderBackButton,
} from "@react-navigation/stack";
import { HomeScreen, GameScreen } from "screens";
// import { DataStore } from "@aws-amplify/datastore";
import config from "./aws-exports";
import { mainHeaderOptions } from "theme";
import { get } from "lodash";
import Header from "components/Header/Header";
import { useI18n } from "hooks/use-i18n";

Amplify.configure(config);
Analytics.configure({ disabled: true });

const Stack = createStackNavigator();

interface IProps {
  onStateChange(eventName: string, comp: any): void;
}

export default function App() {
  const analytics = new GA(Constants.manifest.extra.analytics);
  useI18n();
  useUpdateCheck();
  useEffect(() => {
    analytics.event(new Event("App", "Load"));

    const beforeunload = (e) => {
      e.preventDefault();
      e.returnValue = confirm("Do you want to leave the game?");
    };

    const errorHandler = (e: ErrorEvent) => {
      analytics.hit(
        new ExceptionHit(
          `${window.location.href}: (${e.lineno}) - ${JSON.stringify(e)}`
        )
      );
    };
    if (Platform.OS === "web") {
      window.addEventListener("beforeunload", beforeunload);
      window.addEventListener("error", errorHandler);
    }
    return () => {
      analytics.event(new Event("App", "Leave"));
      if (Platform.OS === "web") {
        window.removeEventListener("beforeunload", beforeunload);
        window.removeEventListener("error", errorHandler);
      }
    };
  }, []);

  const [fontsLoaded] = useFonts({
    "CraftyGirls-Regular": require("./assets/fonts/CraftyGirls-Regular.ttf"),
    "FrederickatheGreat-Regular": require("./assets/fonts/FrederickatheGreat-Regular.ttf"),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: "#ffffff",
    },
  };

  return (
    <NavigationContainer
      theme={MyTheme}
      linking={{
        prefixes: [],
        config: {
          [routes.home]: "",
          [routes.game]: "game/:id",
        },
      }}
    >
      <Stack.Navigator headerMode="none">
        <Stack.Screen name={routes.home} component={HomeScreen} />
        <Stack.Screen
          name={routes.game}
          component={GameScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
