import React, { useMemo, useCallback, useState, useRef } from "react";
import { useFocusEffect } from "@react-navigation/native";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  LayoutChangeEvent,
  Dimensions,
  Platform,
} from "react-native";
import Card from "components/Card/Card";
import { GetCardQuery } from "API";
import { CardModel } from "components";
import { includes } from "lodash";

interface IProps {
  cards: CardModel[];
  pairs: string[];
  cardClicked(card: CardModel): void;
  disabled: boolean;
}

export function Deck({ cards, pairs, cardClicked, disabled }: IProps) {
  const viewRef = useRef<View>(null);
  const [basisStyle, setBasisStyle] = useState<StyleProp<ViewStyle>>({
    flexBasis: "21%",
  });

  useFocusEffect(
    useCallback(() => {
      if (cards.length > 0 && Platform.OS === "web") {
        window.dispatchEvent(new Event("resize"));
      }
    }, [cards, pairs])
  );

  return useMemo(
    () => (
      <View
        style={styles.container}
        onLayout={(e) => _onViewLayoutChange(e, cards.length, setBasisStyle)}
        ref={viewRef}
      >
        {cards.map((card) => {
          const hidden = includes(pairs, card.name);
          return (
            <TouchableOpacity
              onPress={() => cardClicked(card)}
              key={card.id}
              disabled={card.open || hidden || disabled}
              style={basisStyle}
            >
              <Card card={card} hidden={hidden} key={card.id} />
            </TouchableOpacity>
          );
        })}
      </View>
    ),
    [cards, pairs, disabled, basisStyle]
  );
}

const _onViewLayoutChange = (
  event: LayoutChangeEvent,
  numberOfCards: number,
  setBasisStyle: React.Dispatch<React.SetStateAction<StyleProp<ViewStyle>>>
) => {
  if (numberOfCards == 0) return;
  const { width, x } = event.nativeEvent.layout;
  const screenHeight = Math.round(Dimensions.get("window").height);
  const height = screenHeight - x - 30;
  let ratio = 1; // height/width
  // this loop is really slow, you may want to find a faster way
  let col = 0;
  let accWidth, accHeight, numRows;
  do {
    col++;
    if (col > numberOfCards) {
      // maximize height
      setBasisStyle({ flexBasis: height / ratio });
      return;
    }
    accWidth = width / col;
    accHeight = accWidth * ratio;
    numRows = Math.ceil(numberOfCards / col);
  } while (accHeight * numRows > height);
  setBasisStyle({ flexBasis: `${100 / col}%` });
  return;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
    width: "100%",
    height: "100%",
  },
});
