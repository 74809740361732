import React, { useMemo, useCallback } from "react";
import {
  Image,
  StyleSheet,
  ImageSourcePropType,
  View,
  Platform,
} from "react-native";
import ResponsiveImage, { ResponsiveSVG } from "./ResponsiveImage";
import Svg1 from "assets/cards/svg/1.svg";
import Svg2 from "assets/cards/svg/2.svg";
import Svg3 from "assets/cards/svg/3.svg";
import Svg4 from "assets/cards/svg/4.svg";
import Svg5 from "assets/cards/svg/5.svg";
import Svg6 from "assets/cards/svg/6.svg";
import Svg7 from "assets/cards/svg/7.svg";
import Svg8 from "assets/cards/svg/8.svg";
import Svg9 from "assets/cards/svg/9.svg";
import Svg10 from "assets/cards/svg/10.svg";
import Svg11 from "assets/cards/svg/11.svg";
import Svg12 from "assets/cards/svg/12.svg";
import Svg13 from "assets/cards/svg/13.svg";
import Svg14 from "assets/cards/svg/14.svg";
import Svg15 from "assets/cards/svg/15.svg";
import Svg16 from "assets/cards/svg/16.svg";
import Svg17 from "assets/cards/svg/17.svg";
import Svg18 from "assets/cards/svg/18.svg";
import Svg19 from "assets/cards/svg/19.svg";
import Svg20 from "assets/cards/svg/20.svg";
import Svg21 from "assets/cards/svg/21.svg";
import Svg22 from "assets/cards/svg/22.svg";
import Svg23 from "assets/cards/svg/23.svg";
import Svg24 from "assets/cards/svg/24.svg";
import Svg25 from "assets/cards/svg/25.svg";
import Svg26 from "assets/cards/svg/26.svg";
import Hidden from "assets/cards/svg/hidden.svg";
import MemoMeBack from "assets/cards/svg/memome.svg";
import { CardModel } from "components";
import { Asset } from "expo-asset";

interface IProps {
  card: CardModel;
  hidden: boolean;
}
type MapType = Record<string, any>;

const svgAssets: MapType = {
  "1": Svg1({}),
  "2": Svg2({}),
  "3": Svg3({}),
  "4": Svg4({}),
  "5": Svg5({}),
  "6": Svg6({}),
  "7": Svg7({}),
  "8": Svg8({}),
  "9": Svg9({}),
  "10": Svg10({}),
  "11": Svg11({}),
  "12": Svg12({}),
  "13": Svg13({}),
  "14": Svg14({}),
  "15": Svg15({}),
  "16": Svg16({}),
  "17": Svg17({}),
  "18": Svg18({}),
  "19": Svg19({}),
  "20": Svg20({}),
  "21": Svg21({}),
  "22": Svg22({}),
  "23": Svg23({}),
  "24": Svg24({}),
  "25": Svg25({}),
  "26": Svg26({}),
};

export default function Card({ card, hidden }: IProps) {
  return useMemo(
    () => (
      <View style={!hidden ? styles.container : styles.hiddenContainer}>
        {!hidden && card.open && (
          <ResponsiveSVG srcHeight={300} srcWidth={300}>
            {svgAssets[card.name!]}
          </ResponsiveSVG>
        )}
        {!hidden && !card.open && (
          <ResponsiveSVG srcHeight={300} srcWidth={300}>
            <MemoMeBack width="50%" />
          </ResponsiveSVG>
        )}
        {hidden && (
          <ResponsiveSVG srcHeight={300} srcWidth={300}>
            <Hidden />
          </ResponsiveSVG>
        )}
      </View>
    ),
    [card.open, hidden]
  );
}

const styles = StyleSheet.create({
  container: {
    width: "95%",
    // height: 0,
    // paddingBottom: "95%",
    margin: 5,
    ...elevationShadowStyle(5),
  },
  hiddenContainer: {
    width: "95%",
    // height: 0,
    // paddingBottom: "95%",
    margin: 5,
  },
  closed: {
    backgroundColor: "#cccccc",
    height: "100%",
    width: "100%",
  },
});

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0.5 * elevation },
    shadowOpacity: 0.3,
    shadowRadius: 0.8 * elevation,
  };
}
