import { useRef, useState, useEffect, RefObject } from "react";
import {
  NavigationContainerRef,
  useLinking,
  NavigationState,
} from "@react-navigation/native";

export const routes = {
  home: "Home",
  game: "Game",
};
