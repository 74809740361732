import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  StyleSheet,
  View,
  TouchableHighlight,
  Text,
  ScrollView,
  ScrollViewBase,
  Clipboard,
} from "react-native";
import { Input } from "react-native-elements";
import { useScrollToTop, useNavigation } from "@react-navigation/native";
import Modal from "./Modal/Modal";
import { useFocusEffect } from "@react-navigation/native";
import { API, graphqlOperation } from "aws-amplify";
import { onCardUpdate, onGameUpdate } from "graphql/subscriptions";
import { getGame, getCard, cardsByGame } from "graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import useNavigationParam from "lib/use-navigation-param";
import { Observable } from "zen-observable";
import { Deck } from "./Deck";
import { Button } from "react-native-elements";
import { shuffle, includes, sortBy, findIndex, max, range } from "lodash";
import LottieView from "components/Lottie/LottieView";
import Header from "components/Header/Header";
import * as Sharing from "expo-sharing";
import Constants from "expo-constants";
import { Analytics as GA, Event } from "expo-analytics";
import * as WebBrowser from "expo-web-browser";
import {
  GetGameQuery,
  GetCardQuery,
  CardsByGameQuery,
  CreateCardMutation,
  UpdateGameInput,
  CreateCardInput,
  OnUpdateGameSubscription,
  OnUpdateCardSubscription,
  UpdateCardMutation,
  UpdateCardInput,
  OnCardUpdateSubscription,
  OnGameUpdateSubscription,
  UpdateGameMutation,
} from "API";
import { updateGame, updateCard, createCard } from "graphql/mutations";
import { useMutex } from "react-context-mutex";
import { GameLayout } from "models";
import { CardModel, GameModel } from "components";
import { useGameState } from "components/store";
import octopusAnimation from "assets/animations/octopus-guitar.json";
import confettiAnimation from "assets/animations/confetti2.json";
import trophyAnimation from "assets/animations/trophy.json";
import dinoAnimation from "assets/animations/dino-dance.json";
import { ConsoleLogger } from "@aws-amplify/core";
import { useI18n } from "hooks/use-i18n";

export default function Game() {
  const analytics = new GA(Constants.manifest.extra.analytics);
  const [i18nKeys, i18nGet] = useI18n();
  const id = useNavigationParam("id");
  const [state, actions] = useGameState();
  const [nameDialogVisible, setNameDialogVisible] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [showAlertTimer, setShowAlertTimer] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const textInputRef = useRef<Input>(null);
  const confettiRef = useRef<LottieView>(null);
  const confettiViewRef = useRef<View>(null);
  const navigation = useNavigation();
  const MutexRunner = useMutex();
  const onCardClickedMutex = new MutexRunner("onCardClicked");

  const [selectedCardId, setSelectedCardId] = useState("");

  const loadGame = (id: string) =>
    API.graphql(graphqlOperation(getGame, { id })) as Promise<
      GraphQLResult<GetGameQuery>
    >;

  const loadCards = (id: string) =>
    API.graphql(
      graphqlOperation(cardsByGame, { gameCardId: id, limit: 40 })
    ) as Promise<GraphQLResult<CardsByGameQuery>>;

  const addPlayer = async (name: string) => {
    window.document.title = `${name} ${i18nGet(i18nKeys.InvitedToPlay)}`;
    if (includes(state.game?.players, playerName)) {
      return;
    }
    const players = [...(state.game?.players || []), name];
    const scores = [...(state.game?.scores || []), 0];
    await API.graphql(
      graphqlOperation(updateGame, {
        input: {
          id: state.game!.id,
          players,
          scores,
          currentPlayer: state.game.currentPlayer || 0,
          _version: state.game._version,
        } as GameModel,
      })
    );
  };

  const updateHeader = (game: GameModel) => {
    console.log(game);
    if (game?.players) {
      const playersAndScores = game.players.map((p, i) => {
        return `${p}: ${game.scores![i]}`;
      });
      console.log(game.players, playersAndScores);
      navigation.setOptions({ title: playersAndScores?.join(" | ") });
    }
  };

  const cardSelected = (card: CardModel, open: boolean) => {
    return API.graphql(
      graphqlOperation(updateCard, {
        input: {
          id: card.id,
          open,
          _version: card._version,
        } as UpdateCardInput,
      })
    ) as Promise<GraphQLResult<UpdateCardMutation>>;
  };

  const hasTurn = (game: GameModel) => {
    if (game && game.players) {
      const numOpen = state.cards.filter((x) => x.open).length;
      const playerIndex = findIndex(game.players, (x) => x === playerName);
      return numOpen < 2 && game.currentPlayer == playerIndex;
    }
    return false;
  };

  const isCurrentPlayer = () => {
    if (state.game && state.game.players) {
      const playerIndex = findIndex(
        state.game.players,
        (x) => x === playerName
      );
      return state.game.currentPlayer == playerIndex;
    }
    return false;
  };

  const updateGameWithMatch = async (
    game: GameModel,
    pairName: string,
    playerIndex: number
  ) =>
    API.graphql(
      graphqlOperation(updateGame, {
        input: {
          id: game!.id,
          identifiedPairs: [...(game.identifiedPairs || []), pairName],
          scores: game.scores!.map((x, i) => (i == playerIndex ? x! + 1 : x)),
          _version: game._version,
        } as GameModel,
      })
    ) as Promise<GraphQLResult<UpdateGameMutation>>;

  const updateCurrentPlayer = async (game: GameModel, playerIndex: number) => {
    API.graphql(
      graphqlOperation(updateGame, {
        input: {
          id: game!.id,
          currentPlayer: playerIndex,
          _version: game._version,
        } as GameModel,
      })
    ) as Promise<GraphQLResult<UpdateGameMutation>>;
  };

  const nextPlayerIndex = (currentIndex: number, players: string[]) => {
    if (players.length == 1) {
      return 0;
    }
    if (currentIndex == players.length - 1) {
      return 0;
    }
    return currentIndex + 1;
  };

  const celebrate = () => {
    const style = [styles.container, styles.containerContent, styles.confetti];

    confettiRef.current?.play();
    confettiViewRef?.current?.setNativeProps({
      style: [...style, { display: "flex" }],
    });
    setTimeout(() => {
      confettiRef.current?.reset();
      confettiViewRef?.current?.setNativeProps({
        style: [...style, { display: "none" }],
      });
    }, 2000);
  };

  const resetGame = (game: GameModel) => {
    API.graphql(
      graphqlOperation(updateGame, {
        input: {
          id: game!.id,
          identifiedPairs: null,
          scores: game.scores!.map((x) => 0),
          isDone: false,
          _version: game._version,
        } as GameModel,
      })
    ) as Promise<GraphQLResult<UpdateGameMutation>>;
  };

  const getWinnerNamesFromGame = (game: GameModel): string[] => {
    const maxScore = max(game?.scores);
    const winners = [] as string[];
    game?.scores?.forEach((s, i) => {
      if (s == maxScore) {
        winners.push(game!.players![i]);
      }
    });
    return winners;
  };

  const gameOver = async (cards: CardModel[], game: GameModel) => {
    //Show animation
    const updatedGame = await (API.graphql(
      graphqlOperation(updateGame, {
        input: {
          id: game!.id,
          isDone: true,
          _version: game._version,
        } as GameModel,
      })
    ) as Promise<GraphQLResult<UpdateGameMutation>>);
    analytics.event(
      new Event("Game", "Done", id, state.game.identifiedPairs?.length)
    );
    setTimeout(
      async () => {
        const promises = await reShuffleCards(cards);
        const updatedCards = await Promise.all(promises);
        actions.updateCards(
          sortBy(
            updatedCards.map((c) => c.data?.updateCard as CardModel, "order")
          )
        );
        await resetGame(updatedGame.data?.updateGame as GameModel);
      },

      10000
    );
  };

  function gameUpdated(game: GameModel) {
    actions.updateGame(game);
    updateHeader(game);
  }
  function cardUpdated(card: CardModel) {
    actions.updateCard(card);
  }

  useUpdateSubscriptions(id, gameUpdated, cardUpdated);

  useFocusEffect(
    useCallback(() => {
      setSelectedCardId("");
      resetAlertToPlay();
    }, [state.game.currentPlayer])
  );

  useFocusEffect(
    useCallback(() => {
      // We only care about want to analyze the match for the current player
      if (!isCurrentPlayer()) return;
      async function run() {
        const openCards = state.cards.filter((x) => x.open);
        if (openCards.length == 2) {
          const playerIndex = findIndex(
            state.game.players,
            (x) => x == playerName
          );
          //Declare Match
          if (openCards[0].name === openCards[1].name) {
            analytics.event(
              new Event("Game", "Match", id, state.game.identifiedPairs?.length)
            );
            celebrate();
            await updateGameWithMatch(
              state.game,
              openCards[0].name!,
              playerIndex
            );
          } else {
            //Move to next player
            await updateCurrentPlayer(
              state.game,
              nextPlayerIndex(playerIndex, state.game.players!)
            );
          }
          setTimeout(() => {
            openCards.map(async (x) => {
              cardSelected(x, false);
            });
          }, 2000);
        }
      }
      run();
    }, [state.cards])
  );

  useFocusEffect(
    useCallback(() => {
      // What if its the last pair, declare a winner
      if (isCurrentPlayer()) {
        if (state.game?.identifiedPairs?.length == state.cards.length / 2) {
          gameOver(state.cards, state.game);
        }
      }
    }, [state.game?.identifiedPairs?.length])
  );

  useFocusEffect(
    useCallback(() => {
      async function run() {
        if (!selectedCardId) return;
        const card = state.cards.find((x) => x.id === selectedCardId)!;
        if (card.open) return;
        analytics.event(
          new Event("Game", "CardClick", id, state.game.identifiedPairs?.length)
        );
        await cardSelected(card, true);
      }
      run();
    }, [selectedCardId])
  );

  async function onCardClicked(cardId: string) {
    // Reset the timer
    resetAlertToPlay();
    setSelectedCardId(cardId);
  }

  async function reShuffleCards(cards: CardModel[]) {
    const promises = shuffle(cards).map(
      async (x, index) =>
        await (API.graphql(
          graphqlOperation(updateCard, {
            input: {
              id: x.id,
              order: index,
              open: false,
              _version: x._version,
            } as UpdateCardInput,
          })
        ) as Promise<GraphQLResult<UpdateCardMutation>>)
    );
    return promises;
  }

  async function initializeCards() {
    const temCards = Array<CardModel>();
    const randomCardNames = shuffle(range(1, 27)).slice(0, 12);
    for (var index = 0; index < 12; index++) {
      temCards.push({
        name: `${randomCardNames[index]}`,
        gameCardId: id,
      } as CardModel);
      temCards.push({
        name: `${randomCardNames[index]}`,
        gameCardId: id,
      } as CardModel);
    }
    const promises = shuffle(temCards).map(
      async (x, index) =>
        await (API.graphql(
          graphqlOperation(createCard, {
            input: {
              order: index,
              name: x.name,
              gameCardId: x.gameCardId,
              open: false,
            } as CreateCardInput,
          })
        ) as Promise<GraphQLResult<CreateCardMutation>>)
    );

    const newCards = await Promise.all(promises);
    actions.updateCards(
      sortBy(
        newCards.map((c) => c.data?.createCard as CardModel),
        "order"
      )
    );
  }

  useFocusEffect(
    useCallback(() => {
      analytics.event(
        new Event("Game", "Load", id, state.game.players?.length)
      );
      Sharing.isAvailableAsync().then((value) => {
        setCanShare(value);
      });
      loadGame(id).then((game) => {
        analytics.event(
          new Event("Game", "Loaded", id, state.game.players?.length)
        );
        const gameModel = game.data?.getGame as GameModel;
        actions.updateGame(gameModel);
        updateHeader(gameModel);
        loadCards(id).then((cards) => {
          if (cards.data?.cardsByGame?.items!.length > 0) {
            actions.updateCards(cards.data?.cardsByGame?.items as CardModel[]);
          } else {
            initializeCards();
          }
        });
      });
      if (!playerName) {
        setNameDialogVisible(true);
      }
    }, [id])
  );

  // Show Alert
  useFocusEffect(
    useCallback(() => {
      const playerIndex = findIndex(
        state.game.players,
        (x) => x === playerName
      );
      if (
        playerIndex == state.game.currentPlayer &&
        (state.game.players?.length || 0) > 1
      ) {
        const timer = setTimeout(() => {
          setShowAlert(true);
        }, 4000);
        setShowAlertTimer(timer);
        return () => clearTimeout(timer);
      }
    }, [state.game.currentPlayer, state.game.players, state.cards])
  );

  const resetAlertToPlay = () => {
    clearTimeout(showAlertTimer);
    setShowAlertTimer(0);
    setShowAlert(false);
  };

  const header = state.game.players?.map((p, i) => ({
    name: p,
    score: state.game.scores![i] || 0,
    hasTurn:
      state.game.players &&
      (state.game.currentPlayer || 0) < state.game.players.length
        ? p === state.game.players[state.game.currentPlayer || 0]
        : false,
  }));

  return (
    <>
      <Header players={header || []} id={id} showAlert={showAlert} />
      <Confetti
        animation={confettiAnimation}
        animationRef={confettiRef}
        viewRef={confettiViewRef}
      />
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.containerContent}
      >
        <Deck
          pairs={state.game.identifiedPairs as string[] | []}
          cards={state.cards}
          cardClicked={(card) => {
            onCardClicked(card.id);
          }}
          disabled={!hasTurn(state.game)}
        />
      </ScrollView>
      <PlayerNameDialog
        visible={nameDialogVisible}
        textInputRef={textInputRef}
        onOKPress={() => {
          if (includes(state.game.players, playerName)) {
            textInputRef.current?.shake();
            textInputRef.current?.clear();
            setErrorMsg(i18nGet(i18nKeys.NameInUse));
            return;
          }
          if (!playerName) {
            textInputRef.current?.shake();
            textInputRef.current?.clear();
            return;
          }
          setNameDialogVisible(false);
          addPlayer(playerName);
          setReadyToPlay(true);
          analytics.event(
            new Event("Game", "Name", id, state.game.players?.length)
          );
        }}
        errorMsg={errorMsg}
        setPlayerName={setPlayerName}
        setErrorMsg={setErrorMsg}
      />
      {readyToPlay && state.game.players && state.game.players!.length == 1 && (
        <WaitForPlayerDialog
          visible
          animation={octopusAnimation}
          canShare={canShare}
          onOKPress={() => {
            analytics.event(
              new Event("Game", "Shared", id, state.game.players?.length)
            );
          }}
        />
      )}
      <WinnerDialog
        visible={state.game.isDone || false}
        animation={trophyAnimation}
        winnerNames={getWinnerNamesFromGame(state.game)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerContent: {
    alignItems: "center",
    justifyContent: "center",
  },
  confetti: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "none",
    zIndex: 1,
  },
});

const modalStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100%",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    padding: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  error: {
    color: "#ff0000",
  },
});

interface DialogProps {
  textInputRef?: any;
  visible: boolean;
  setPlayerName?(a: string): void;
  onOKPress?(): void;
  animation?: any;
  winnerNames?: string[];
  canShare?: boolean;
  setErrorMsg?(a: string): void;
  errorMsg?: string;
  hasShared?: boolean;
}

const PlayerNameDialog = ({
  visible,
  textInputRef,
  setPlayerName,
  onOKPress,
  setErrorMsg,
  errorMsg,
}: DialogProps) => {
  const [i18nKeys, i18nGet] = useI18n();
  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View style={modalStyles.centeredView}>
        <View style={modalStyles.modalView}>
          <LottieView
            loop
            autoPlay
            style={{
              width: 200,
              height: 200,
              backgroundColor: "#fff",
            }}
            source={dinoAnimation}
          />
          <Text>{i18nGet(i18nKeys.GetStarted)}</Text>
          <Input
            placeholder={i18nGet(i18nKeys.EnterName)}
            onChangeText={(text) => {
              setErrorMsg!("");
              setPlayerName!(text);
            }}
            style={modalStyles.textField}
            ref={textInputRef}
          />
          {errorMsg!.length > 0 && (
            <Text style={modalStyles.error}>{errorMsg}</Text>
          )}
          <Button
            style={{ backgroundColor: "#2196F3" }}
            onPress={onOKPress}
            title={i18nGet(i18nKeys.Done)}
          />
        </View>
      </View>
    </Modal>
  );
};

const WaitForPlayerDialog = ({
  visible,
  animation,
  canShare,
  onOKPress,
}: DialogProps) => {
  const [i18nKeys, i18nGet] = useI18n();
  const [hasShared, setHasShared] = useState(false);

  useEffect(() => {
    if (!visible) {
      setHasShared(false);
    }
  }, [visible]);
  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View style={modalStyles.centeredView}>
        <View style={modalStyles.modalView}>
          <LottieView
            loop
            autoPlay
            style={{
              width: 200,
              height: 200,
              backgroundColor: "#eee",
            }}
            source={animation}
          />
          {hasShared && <Text>{i18nGet(i18nKeys.WaitingForPlayer)}</Text>}
          {canShare && !hasShared && (
            <Button
              type="clear"
              title={i18nGet(i18nKeys.Sharing)}
              onPress={() =>
                Sharing.shareAsync(window.location.href, {
                  dialogTitle: i18nGet(i18nKeys.Sharing),
                }).then(() => {
                  setHasShared(true);
                  onOKPress!();
                })
              }
            />
          )}
          {!canShare && (
            <Button
              type="clear"
              title={i18nGet(i18nKeys.Sharing)}
              onPress={() =>
                WebBrowser.openBrowserAsync(
                  `mailto:?subject=${i18nGet(i18nKeys.InviteTitle)}&body=${
                    window.location.href
                  }`
                ).then(() => {
                  setHasShared(true);
                  onOKPress!();
                })
              }
            />
          )}
        </View>
      </View>
    </Modal>
  );
};

const WinnerDialog = ({ visible, animation, winnerNames }: DialogProps) => {
  const ref = useRef<LottieView>(null);
  const [i18nKeys, i18nGet] = useI18n();

  useEffect(() => {
    if (ref.current && visible) {
      setTimeout(() => {
        ref.current?.play();
      }, 500);
    } else {
      ref.current?.reset();
    }
  }, [ref.current, visible]);
  return useMemo(
    () => (
      <Modal animationType="slide" transparent={true} visible={visible}>
        <View style={modalStyles.centeredView}>
          <View style={modalStyles.modalView}>
            <LottieView
              ref={ref}
              style={{
                width: 200,
                height: 200,
                backgroundColor: "#fff",
              }}
              source={animation}
            />
            {visible && (
              <>
                <Text>{`${winnerNames?.join(
                  ` ${i18nGet(i18nKeys.And)} `
                )} ${i18nGet(i18nKeys.WinnerPostfix)}`}</Text>
                <Text>{`(${i18nGet(i18nKeys.NextRound)})`}</Text>
              </>
            )}
          </View>
        </View>
      </Modal>
    ),
    [visible]
  );
};

interface AnimationProps {
  animation: any;
  animationRef: any;
  viewRef: any;
}
const Confetti = ({ animation, animationRef, viewRef }: AnimationProps) => (
  <View
    ref={viewRef}
    style={[styles.container, styles.containerContent, styles.confetti]}
  >
    <LottieView ref={animationRef} source={animation} />
  </View>
);

const useUpdateSubscriptions = (
  currentGameId: string,
  gameUpdated: any,
  cardUpdated: any
) => {
  const callback = useCallback(() => {
    if (currentGameId) {
      const gameSubscription = (API.graphql(
        graphqlOperation(onGameUpdate, { id: currentGameId })
      ) as Observable<object>).subscribe({
        next: (result: { value: GraphQLResult<OnGameUpdateSubscription> }) => {
          gameUpdated(result.value.data?.onGameUpdate as GameModel);
        },
      });

      const cardSubscription = (API.graphql(
        graphqlOperation(onCardUpdate, { gameCardId: currentGameId })
      ) as Observable<object>).subscribe({
        next: (result: { value: GraphQLResult<OnCardUpdateSubscription> }) => {
          cardUpdated(result.value.data?.onCardUpdate as CardModel);
        },
      });
      return () => {
        gameSubscription.unsubscribe();
        cardSubscription.unsubscribe();
      };
    }
  }, [currentGameId]);

  useFocusEffect(callback);
};
