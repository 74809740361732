export const schema = {
    "models": {
        "Game": {
            "name": "Game",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "players": {
                    "name": "players",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "scores": {
                    "name": "scores",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "layout": {
                    "name": "layout",
                    "isArray": false,
                    "type": {
                        "enum": "GameLayout"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "identifiedPairs": {
                    "name": "identifiedPairs",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cardOrder": {
                    "name": "cardOrder",
                    "isArray": true,
                    "type": {
                        "model": "Card"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "game"
                    }
                },
                "isDone": {
                    "name": "isDone",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Games",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "BySlug",
                        "fields": [
                            "slug"
                        ],
                        "queryField": "gamesBySlug"
                    }
                }
            ]
        },
        "Card": {
            "name": "Card",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "game": {
                    "name": "game",
                    "isArray": false,
                    "type": {
                        "model": "Game"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "cardGameId"
                    }
                },
                "gameCardId": {
                    "name": "gameCardId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Cards",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                }
            ]
        }
    },
    "enums": {
        "GameLayout": {
            "name": "GameLayout",
            "values": [
                "g3x3",
                "g3x4",
                "g4x4",
                "g4x5"
            ]
        }
    },
    "nonModels": {},
    "version": "862e0280aa70d563391808c0286179e7"
};