class Serializable {
  constructor(props) {
    this.properties = props || {};
  }

  toObject() {
    return this.properties;
  }

  toString() {
    return JSON.stringify(this.toObject());
  }

  toJSON() {
    return JSON.stringify(this.properties);
  }

  toQueryString() {
    var str = [];
    var obj = this.toObject();
    for (var p in obj) {
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
}

class Hit extends Serializable {
  sent = false;
  constructor(props) {
    super(props);
  }
}

export class ExceptionHit extends Hit {
  constructor(description, fatal = 0) {
    super({ t: "exception", exd: description, exf: fatal ? 1 : 0 });
  }
}
